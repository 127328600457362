.timeline-label:before {
  left: 101px;
}

.timeline-label.timeline-size-120:before,
.timeline-label.timeline-size-120 .timeline-label:before {
  left: 121px;
}

.timeline-label.timeline-size-140:before,
.timeline-label.timeline-size-140 .timeline-label:before {
  left: 141px;
}

.timeline-label.timeline-size-160:before,
.timeline-label.timeline-size-160 .timeline-label:before {
  left: 161px;
}


.timeline-label .timeline-item {
  overflow: hidden;
}

.timeline-label .timeline-label {
  width: 100px;
}

.timeline-label.timeline-size-120 .timeline-label {
  width: 120px;
}

.timeline-label.timeline-size-140 .timeline-label {
  width: 140px;
}

.timeline-label.timeline-size-160 .timeline-label {
  width: 160px;
}

.timeline-label .timeline-content span {
  white-space: nowrap;
}